<template>
	<div class="cartBox">
		<div class="cart_content">
			<Step :steps="1"></Step>
			<div>
				<div class="table_box" v-if="goodsData.length != 0">
					<div class="allGoods flex-center">全部商品</div>
					<div class="table_th flex-center">
						<div class="table_td1 flex-center">
							<input
								class="checks pointer"
								@click="chckedBtn('all')"
								type="checkbox"
								v-model="allCheck"
							/>
							<div class="all">全选</div>
						</div>
						<div class="table_td2 flex-center">
							<div class="goodsName">商品名称</div>
						</div>
						<div class="table_td3 flex-center">
							<div class="goodsName">单价(元)</div>
						</div>
						<div class="table_td4 flex-center">
							<div class="goodsName">数量</div>
						</div>
						<div class="table_td5 flex-center">
							<div class="goodsName">小计</div>
						</div>
						<div class="table_td5 flex-center">
							<div class="goodsName">操作</div>
						</div>
					</div>
					<div
						class="table_tr flex-center pointer"
						v-for="(item, index) in goodsData"
						:key="index"
					>
						<div
							v-if="item.onShelf == 1"
							class="table_td1 flex"
							@click.stop="chckedBtn('single', item)"
						>
							<input
								class="checks pointer"
								v-model="item.isChecked"
								type="checkbox"
							/>
							<img
								@click="toGoods(item.goodsId)"
								:src="item.coverUrl ? item.coverUrl : imgs"
								alt=""
							/>
						</div>
						<div
							class="table_td2 flex-center column"
							@click="toGoods(item.goodsId)"
						>
							<div class="goodsName row">{{ item.productName }}</div>
							<div class="specifications">{{ item.skuTitle }}</div>
						</div>
						<div class="table_td3 flex-center">
							<div class="goodsPrice">
								￥{{ Number(item.unitPrice).toFixed(2) }}
							</div>
						</div>
						<div class="table_td4 flex-center">
							<el-input-number
								:min="1"
								@change="handleChangeBtn(item, index)"
								size="mini"
								v-model="item.num"
							></el-input-number>
						</div>
						<div class="table_td5 flex-center">
							<div class="goodsCalculate">
								￥{{ Number(item.totalPrice).toFixed(2) }}
							</div>
						</div>
						<div class="table_td5 flex column">
							<div
								class="btn delBtn pointer"
								@click.stop="delBtn(item.id, index)"
							>
								删除
							</div>
							<div
								class="btn collectionBtn pointer"
								@click.stop="collectionBtn(item)"
							>
								{{ item.isCollect == 0 ? '移入收藏' : '取消收藏' }}
							</div>
						</div>
						<img
							v-if="item.onShelf != 1"
							class="icon_shelves"
							src="@/assets/img/shelves.png"
							alt=""
						/>
					</div>
					<div class="table_th_bottom Between">
						<div class="fl flex-center">
							<input
								class="checks pointer"
								@click="chckedBtn('all')"
								type="checkbox"
								v-model="allCheck"
							/>
							<div class="all">全选</div>
							<div class="delGoods pointer" @click="delBtn('all')">
								删除选中的商品
							</div>
						</div>
						<div class="fr flex-center">
							已选择
							<div class="selectedNum">{{ totalCount }}</div>
							件商品 &nbsp
							<!-- 优惠：￥<div class="discountsPrice">0.00</div>&nbsp     -->
							总价（不含邮费）:
							<div class="totalPrice">
								￥{{ Number(totalPrice).toFixed(2) }}
							</div>
							<div class="close_account display pointer" @click="placeOrder">
								去结算
							</div>
						</div>
					</div>
				</div>
				<div class="pageBox" v-if="total != 0 || $store.state.cartNum != 0">
					<Pages :total="total" :params="params" @jump="pageSkip"></Pages>
				</div>
				<div class="cart_empty_box display" v-else>
					<img class="icon_empty" src="@/assets/img/cartEmpty.png" alt="" />
					<div class="cart_empty">
						购物车空空的哦~，去看看心仪的商品吧~
						<div class="btn display pointer" @click="buyGoods">去购物 ></div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
import Step from './components/step.vue'
import Pages from '@/components/pages.vue'
export default {
	components: {
		Step,
		Pages,
	},
	data() {
		return {
			imgs: require('../../assets/img/noImg.jpg'),
			steps: 1,
			allCheck: false,
			gooodsNum: 1,
			checkList: 0,
			totalPrice: 0,
			totalCount: 0,
			params: {
				page: 1,
				limit: 10,
				token: this.$store.state.userData.token,
			},
			total: 0,
			goodsData: [],
			skuData: [],
		}
	},
	mounted() {
		if (JSON.parse(localStorage.getItem('skuData'))) {
			this.skuData = JSON.parse(localStorage.getItem('skuData'))
		}
		if (this.$store.state.userData.token) {
			this.getCartData()
		} else {
			this.goodsData = JSON.parse(localStorage.getItem('cartData'))
		}
	},
	methods: {
		//获取购物车列表
		getCartData() {
			this.api.findAllPageShopCartGoods(this.params).then((res) => {
				if (res.code == 0) {
					this.total = res.count
					this.goodsData = res.data
					this.$store.commit('getcartNum', res.count)
				}
			})
		},
		//全选
		chckedBtn(type, item) {
			if (type == 'all') {
				this.allCheck = !this.allCheck
				this.goodsData.forEach((item) => {
					if (item.onShelf == 1) {
						this.$set(item, 'isChecked', this.allCheck)
					}
				})
			} else {
				if (typeof item.isChecked == 'undefined') {
					this.$set(item, 'isChecked', true)
				} else {
					item.isChecked = !item.isChecked
				}
			}
			this.getPrice()
		},
		// 计算价格
		getPrice() {
			let checkList = [], // 选中的商品 id（集合）
				totalPrice = 0, // 总价
				totalCount = 0 // 选中的商品总数
			this.goodsData.forEach((goods) => {
				if (goods.isChecked) {
					checkList.push(goods.id)
					totalPrice += goods.num * goods.unitPrice
					totalCount++
				}
			})
			if (totalCount > 0 && totalCount == this.goodsData.length) {
				this.allCheck = true
			} else {
				this.allCheck = false
			}
			this.checkList = checkList
			this.totalPrice = totalPrice
			this.totalCount = totalCount
		},
		delBtn(id, index) {
			let params = {
				token: this.$store.state.userData.token,
				arrayIds: '',
			}
			if (this.$store.state.userData.token) {
				if (id == 'all') {
					if (this.checkList == 0) {
						return this.$message.error('请选择要删除的商品')
					} else {
						params.arrayIds = this.checkList.join(',')
					}
				} else {
					params.arrayIds = id
				}
				this.api.delShopCartGoods(params).then((res) => {
					if (res.code == 0) {
						this.$message.success('删除成功！')
						this.getCartData()
					} else {
						this.$message.error(res.msg)
					}
				})
			} else {
				if (id == 'all') {
					if (this.checkList == 0) {
						return this.$message.error('请选择要删除的商品')
					} else {
						this.goodsData = []
						this.skuData = []
						localStorage.removeItem('cartData')
						localStorage.removeItem('skuData')
						this.$store.commit('getcartData', [])
						this.$message.success('删除成功！')
					}
				} else {
					this.goodsData.splice(index, 1)
					this.skuData.splice(index, 1)
					this.$message.success('删除成功2222！')
					localStorage.setItem('cartData', JSON.stringify(this.goodsData))
					localStorage.setItem('skuData', JSON.stringify(this.skuData))
					this.$store.commit('getcartData', this.goodsData)
					this.$store.commit('getcartNum', this.goodsData.length)
				}
			}
		},

		//修改商品数量
		handleChangeBtn(item, index) {
			let params = {
				token: this.$store.state.userData.token,
				id: item.id,
				num: item.num,
				goodsId: item.goodsId,
				skuPriceId: item.skuPriceId,
			}

			if (this.$store.state.userData.token) {
				this.api.updShopCartGoodsNum(params).then((res) => {
					if (res.code == 0) {
						item.totalPrice = res.data.totalPrice
						this.getPrice()
					}
				})
			} else {
				this.skuData[index].num = item.num
				this.goodsData[index].num = item.num
				this.goodsData[index].totalPrice = item.num * item.unitPrice
				localStorage.setItem('skuData', JSON.stringify(this.skuData))
				localStorage.setItem('cartData', JSON.stringify(this.goodsData))
				this.$store.commit('getcartData', this.goodsData)
				this.$store.commit('getcartData', this.goodsData)
				this.getPrice()
			}
		},
		buyGoods() {
			this.$router.push('/mall/goodsList')
		},
		//去结算
		placeOrder() {
			if (this.$store.state.userData.token) {
				if (this.checkList.length == undefined || this.checkList.length == 0) {
					return this.$message.error('请选择商品')
				} else {
					let params = {
						shopCartIds: this.checkList,
					}
					sessionStorage.setItem('confirmOrder', JSON.stringify(params))
					this.$router.push({
						path: '/cart/placeOrder',
						query: {
							type: 'cart',
						},
					})
				}
			} else {
				this.$message.error('请登录！')
				this.$router.push({
					path: '/login',
					query: {
						isLogin: 'no',
					},
				})
			}
		},
		// 收藏
		collectionBtn(item) {
			let params = {
				productId: item.goodsId,
				token: this.$store.state.userData.token,
			}
			if (item.isCollect == 0) {
				this.api.addCollec(params).then((res) => {
					if (res.code == 0) {
						this.$message.success('收藏成功！')
						this.getCartData()
					} else {
						if (res.msg == 'token不能为空') {
							this.$router.push({
								path: '/login',
								query: {
									isLogin: false,
								},
							})
						} else {
							this.$message.error(res.msg)
						}
					}
				})
			} else {
				this.api.delCollect(params).then((res) => {
					if (res.code == 0) {
						this.$message.success('取消收藏成功！')
						this.getCartData()
					} else {
						if (res.msg == 'token不能为空') {
							this.$router.push({
								path: '/login',
								query: {
									isLogin: 'no',
								},
							})
						} else {
							this.$message.error(res.msg)
						}
					}
				})
			}
		},
		//去商品详情
		toGoods(id) {
			this.$router.push({
				path: '/mall/goodsDetails',
				query: {
					id: id,
				},
			})
		},
		//分页
		pageSkip(page) {
			this.params = page
			this.getCartData()
		},
	},
}
</script>
<style lang="scss" scoped>
.cartBox {
	.cart_content {
		width: 1200px;
		margin: 0 auto;
		margin-bottom: 30px;
		.article_steps_box {
			width: 1200px;
			height: 10px;
			background: #aaaaaa;
			border-radius: 5px;
			.list {
				width: 400px;
				height: 10px;
				background: #aaaaaa;
				border-radius: 5px;
				.num {
					width: 28px;
					min-height: 28px;
					background: #db2d39;
					border-radius: 50%;
					color: #fff;
					font-size: 14px;
					margin-top: 25px;
				}
				.label {
					margin-top: 9px;
				}
			}
			.active {
				background: #aaaaaa;
				background: #db2d39;
			}
		}
	}
	.table_box {
		width: 100%;
		border: 1px solid #eaeaea;
		.allGoods {
			width: 100%;
			height: 52px;
			background: white;
			font-size: 18px;
			font-family: Microsoft YaHei;
			font-weight: bold;
			color: #e1251b;
			padding-left: 25px;
			box-sizing: border-box;
		}
		.table_th {
			height: 44px;
			background: #f4f4f4;
			padding-left: 20px;
			box-sizing: border-box;
			border-top: 1px solid #eaeaea;
			border-bottom: 1px solid #eaeaea;
		}
		.table_tr {
			height: 100px;
			background: #fff;
			border-bottom: 1px solid #eaeaea;
			padding-left: 20px;
			box-sizing: border-box;
			position: relative;
			.icon_shelves {
				width: 90%;
				height: 100px;
				position: absolute;
				top: 0;
				left: 0;
				z-index: 66;
			}
			.goodsName {
				width: 300px;
				font-size: 12px;
				font-family: Microsoft YaHei;
				font-weight: 400;
				color: #333333;
				line-height: 20px;
			}
			.specifications {
				width: 300px;
				font-size: 12px;
				color: #888888;
				line-height: 20px;
				margin-top: 19px;
			}
			.goodsPrice {
				font-size: 14px;
				font-family: Microsoft YaHei;
				font-weight: 400;
				color: #666666;
			}
			.goodsCalculate {
				font-size: 16px;
				font-family: Microsoft YaHei;
				font-weight: bold;
				color: #e1251b;
			}
			.btn {
				font-size: 12px;
				color: #333333;
			}
			.delBtn {
			}
			.collectionBtn {
				margin-top: 20px;
			}
		}
		.table_td1 {
			min-width: 126px;
			font-size: 12px;
			font-family: Microsoft YaHei;
			font-weight: bold;
			color: #666666;
			box-sizing: border-box;

			.checks {
				width: 16px;
				height: 16px;
			}
			.all {
				margin-left: 10px;
			}
			img {
				width: 78px;
				height: 78px;
				margin-left: 12px;
			}
		}
		.table_td2 {
			min-width: 300px;
			margin-left: 37px;
			box-sizing: border-box;
		}
		.table_td3 {
			min-width: 100px;
			margin-left: 60px;
			box-sizing: border-box;
		}
		.table_td4 {
			min-width: 100px;
			margin-left: 90px;
			box-sizing: border-box;
		}
		.table_td5 {
			min-width: 100px;
			margin-left: 90px;
			box-sizing: border-box;
		}
		.table_td6 {
			min-width: 100px;
			margin-left: 30px;
			box-sizing: border-box;
		}
	}
	.table_th_bottom {
		.fl {
			font-size: 12px;
			color: #666666;
			padding-left: 20px;
			box-sizing: border-box;
			.checks {
				width: 16px;
				height: 16px;
			}
			.all {
				font-family: Microsoft YaHei;
				font-weight: bold;
				margin-left: 10px;
			}
			.delGoods {
				margin-left: 32px;
			}
		}
		.fr {
			font-size: 12px;
			color: #666;

			.selectedNum {
				font-size: 14px;
				color: #e33a30;
			}
			.discountsPrice {
			}
			.totalPrice {
				font-size: 24px;
				color: #e33a30;
			}
			.close_account {
				width: 160px;
				height: 51px;
				background: #e33a30;
				font-size: 20px;
				font-family: Microsoft YaHei;
				font-weight: bold;
				color: #ffffff;
				margin-left: 24px;
			}
		}
	}
	.cart_empty_box {
		width: 1200px;
		height: 402px;
		background: #ffffff;
		margin-top: 66px;
		margin-bottom: 30px;
		.icon_empty {
			width: 186px;
			height: 166px;
		}
		.cart_empty {
			font-size: 16px;
			color: #666666;
			margin-left: 86px;
			.btn {
				width: 123px;
				height: 44px;
				background: #e62129;
				border-radius: 3px;
				margin-top: 27px;
				font-size: 22px;
				color: #ffffff;
			}
		}
	}
	.pageBox {
		display: flex;
		justify-content: flex-end;
		margin-top: 31px;
		margin-bottom: 52px;
	}
}
</style>